div.showCard { 
    width: 100%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &.hovCard{
       
        margin-bottom: 50px;
        div.bottom{
            
                animation: cardAnimationOut 0.3s ease-in-out;
                @media all and (max-width:980px){
                    animation: none;
                }

                height: 95px !important;
            }
    }
    &:hover{
        transform: scale(1.1);
        z-index: 9;
        @media all and (max-width:980px){
            transform: none;
        }
        div.bottom{
            animation: cardAnimationIn 0.3s ease-in-out forwards;
            z-index: 9;
            @media all and (max-width:980px){
                animation: none;
            }
        }
        div.bottom.desc{
            @media all and (max-width:980px){
                animation: none;
            }
        }
        
        div.bottom div.content{
            padding-bottom: 70px;
            @media all and (max-width:1400px){
                height: 60px;

            }
            @media all and (max-width:1200px){
                height: 80px;
            }
            @media all and (max-width:980px){
                height: 60px;
            }
            
        }
        div.bottom div.content div.dateSection{
            animation: textAnimationIn 0.3s ease-in-out;
            @media all and (max-width:980px){
                animation: none;
            }

        }
        div.bottom div.content p{
            animation: textAnimationIn 0.3s ease-in-out;
            transform:scale(1);
            @media all and (max-width:980px){
                animation: none;
            }
        }
        
        div.bottom div.content div.buttonContainer{
            animation: textAnimationIn 0.3s ease-in-out;
            @media all and (max-width:980px){
                animation: none;
            }
        }
        div.top{
            z-index: 9;
        }
        div.top span.playIcon{
            bottom: 10px;
            div.hoverEffect{
                width: 10px;
                height: 10px;
                background-color: var(--white-color);
                border-radius: 50%;
                position: absolute;
                top: 32%;
                left: 32%;
                transform: translate(-50%,-50%) scale(0);
                transition: 0.3s ease-in-out;
                z-index: 3;
                
            }
        } 
        div.top span.star{
            display: block;
            animation: textAnimationIn 0.3s ease-in-out;
            @media all and (max-width:980px){
                animation: none;
            }
            &.star-filled{
                svg{
                    fill: var(--primary-color);
                }
            }
        }  

    }
	div.top { 
        overflow: hidden;
		div.imageContainer { 
            width: 100%;
            display: block;
            z-index: -1;
			img { 
                width: 100%;
            display: block;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
			}
		}
        span.star{
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 10px;
            display: none;
            z-index: 8;
            transition: 0.3s ease-in-out;
            @media all and (max-width:980px){
                display: block;
                &.star-filled{
                    svg{
                        fill: var(--primary-color);
                    }
                }
            }

        }
        span.playIcon{
            display: inline-block;
            background-color: var(--primary-color);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            bottom: -60px;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-in-out;
            z-index: 8;
            @media all and (max-width:980px){
                bottom: 10px;
            }
            &:hover{
                background-color: var(--white-color);
                svg{
                    fill: var(--primary-color);
                    z-index: 8;
                }
                div.hoverEffect{
                    transform: scale(2.8);
                    transition: 0.3s ease-in-out;
                    animation: zoom 0.3s ease-in-out;
                }
                
            }
            svg{
                width: 20px;
                height: 20px;
            transition: 0.3s ease-in-out;

            }
        }
	}

	div.bottom { 
        background-color: var(--secondary-bg-color);
        padding: 15px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        z-index: -1;

        width: 100%;
        height: 60px;
        
        &.dateCard{
            height: 60px;
            @media all and (max-width:980px){

                height: 70px;
            }
        }
        &.desc{
            @media all and (max-width:980px){

                height: 71px;
            }
        }
       
        @media all and (max-width:1400px){
            padding: 4px 15px 15px;
        }
        
		h3.title { 
            font-size: 14px;
            margin-bottom: 5px;
            letter-spacing: .3px;
            transition: 0.3s ease-in-out;
            overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            @media all and (max-width:1400px){
                font-size: 12px;
            }
            @media all and (max-width:980px){
                &.buttonCard{

                    min-height: 30px;
                }
            }
            @media all and (max-width:768px){
                font-size: 14px;
            }
		}
        div.content{
            overflow: hidden;
            height: 0;
            
            @media all and (max-width:980px){
                height: 60px;
            }
            div.dateSection { 
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                margin-top: 3px;
                span.icon { 
                    width: 20px;
                    display: inline-block;
                    margin-right: 8px;
                    img { 
                        width: 100%;
                        display: block;
                    }
                }
    
                span.date { 
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 100;
                }
            }
    
            p { 
                font-size: 13px;
                font-weight: 400;
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            animation: textAnimationOut 0.3s ease-in-out;
            
            @media all and (max-width:1600px){
                font-size: 12px;
            }
            @media all and (max-width:980px){
                animation: none;
            }
                
            }
            div.buttonContainer{

                
                button{

                    border: 1px solid var(--primary-color);
                    background-color: transparent;
                    color: var(--primary-color);
                    padding: 6px 20px;
                    font-size: 12px;
                    border-radius: 6px;
                    width: 70%;
                    transition: 0.3s ease-in-out;
                    &:hover{

                        border-color: var(--icons-color);
                        color: var(--icons-color);
                    }
                    @media all and (max-width:980px){
                        padding: 2px 10px;
                        font-size: 11px;
                        width: 72%;
                    }
                }
            }
        }
	}
}
@keyframes cardAnimationIn{
    from{
        height: 60px;
    }
    to{
        height: 70px;
    }
}
@keyframes cardAnimationIn2{
    from{
        height: 60px;
    }
    to{
        height:65px ;
    }
}
@keyframes cardAnimationOut{
    from{
        height: 70px;
    }
    to{
        height: 60px;
    }
}
@keyframes textAnimationIn{
    from{
        opacity: 0;
        transform: translateY(-20px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes textAnimationOut{
    to{
        transform: translateY(0);
    }
    from{
        transform: translateY(-20px);
    }
}
@keyframes zoom{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(2.8);
    }
}