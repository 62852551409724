:root {
  --primary-color:#e72a31;
  --bg-color:#101010;
  --secondary-bg-color:#181818;
  --white-color:#f8f8f8;
  --icons-color:#ff0103;
  --hover-color:#1d2124;
}

div.footer-container {
  background-color: var(--hover-color);
}
div.footer-container div.footerWrapper {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper {
    flex-direction: column;
  }
}
div.footer-container div.footerWrapper div.left div.top {
  margin-bottom: 10px;
}
div.footer-container div.footerWrapper div.left div.top ul.links {
  flex-wrap: wrap;
}
div.footer-container div.footerWrapper div.left div.top ul.links li {
  margin-right: 10px;
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.left div.top ul.links li {
    margin-right: 6px;
  }
}
div.footer-container div.footerWrapper div.left div.top ul.links li:last-child {
  margin-right: 0;
}
div.footer-container div.footerWrapper div.left div.top ul.links li span {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}
@media all and (max-width: 1400px) {
  div.footer-container div.footerWrapper div.left div.top ul.links li span {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  div.footer-container div.footerWrapper div.left div.top ul.links li span {
    font-size: 11px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.left div.top ul.links li span {
    font-size: 10px;
  }
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.left div.top ul.links li span {
    font-size: 11px;
  }
}
div.footer-container div.footerWrapper div.left div.center p {
  font-size: 14px;
}
div.footer-container div.footerWrapper div.left div.center p:first-child {
  margin-bottom: 0;
}
@media all and (max-width: 1400px) {
  div.footer-container div.footerWrapper div.left div.center p {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  div.footer-container div.footerWrapper div.left div.center p {
    font-size: 11px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.left div.center p {
    font-size: 10px;
  }
}
div.footer-container div.footerWrapper div.left div.bottom p {
  margin-bottom: 0;
  font-size: 14px;
}
div.footer-container div.footerWrapper div.left div.bottom p span {
  color: var(--text-color);
  font-weight: 600;
}
@media all and (max-width: 1400px) {
  div.footer-container div.footerWrapper div.left div.bottom p {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  div.footer-container div.footerWrapper div.left div.bottom p {
    font-size: 11px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.left div.bottom p {
    font-size: 10px;
  }
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.left div.bottom p {
    font-size: 11px;
  }
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.right {
    margin-top: 20px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.right div.top {
    margin-top: 8px;
  }
}
div.footer-container div.footerWrapper div.right div.top ul.social {
  justify-content: right;
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.right div.top ul.social {
    justify-content: left;
  }
}
div.footer-container div.footerWrapper div.right div.top ul.social li {
  margin-right: 10px;
  width: 20px;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.footer-container div.footerWrapper div.right div.top ul.social li {
    width: 15px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.right div.top ul.social li {
    width: 15px;
  }
}
div.footer-container div.footerWrapper div.right div.top ul.social li:last-child {
  margin-right: 0;
}
div.footer-container div.footerWrapper div.right div.top ul.social li img {
  width: 100%;
  display: block;
}
div.footer-container div.footerWrapper div.right div.bottom {
  margin-top: 20px;
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.right div.bottom {
    margin-top: 10px;
  }
}
div.footer-container div.footerWrapper div.right div.bottom div.imageContainer {
  width: 400px;
}
@media all and (max-width: 1400px) {
  div.footer-container div.footerWrapper div.right div.bottom div.imageContainer {
    width: 296px;
  }
}
@media all and (max-width: 1200px) {
  div.footer-container div.footerWrapper div.right div.bottom div.imageContainer {
    width: 220px;
  }
}
@media all and (max-width: 980px) {
  div.footer-container div.footerWrapper div.right div.bottom div.imageContainer {
    width: 171px;
  }
}
@media all and (max-width: 768px) {
  div.footer-container div.footerWrapper div.right div.bottom div.imageContainer {
    width: 236px;
  }
}
div.footer-container div.footerWrapper div.right div.bottom div.imageContainer img {
  display: block;
  width: 100%;
}/*# sourceMappingURL=Footer.css.map */