div.liveContainer{
    padding: 0 0 0;
    div.wrapper{
        div.languageSelector {
            display: flex;
            justify-content: flex-end;
            position: relative;
            bottom: 10px;
            width: 100%;

            @media all and (max-width:640px){
              width: 95%;
              // margin: 0 auto;
          }
          
            img.language {
              max-width: 30px;
              max-height: 20px;
              margin-left: 10px;
              cursor: pointer;
              opacity: 0.6;
              transition: opacity 0.3s ease;
          
              &.active {
                opacity: 1;
                border: 1px solid #fff;
                border-radius: 3px;
              }
              &:hover{
                opacity: 1;
              }
            }
          }
        div.liveVideoContainer{
            width: 100%;
            .vjs-big-play-button{
                display: none !important;
            }
            @media all and (max-width:640px){
                width: 90%;
                margin: 0 auto;
            }
           
            img{
                width: 100%;
                display: block;
            }   
        }
         h1.nowPlaying { 
                font-size: 22px;
                margin-bottom: 10px;
                @media all and (max-width:640px){
                    padding-left: 10px;
                }
            }

            h1.homeNowPlaying {
                font-size: medium;
                font-weight: 700;
            }
        div.showsContainer{
           
        }
    }

    div.live-home-wrapper{
        margin-top: 15px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        background: linear-gradient(#141414 50%, #fff 0) 0 var(--_d, 0)/100% 200% no-repeat, linear-gradient(var(--secondary-bg-color) 50%, var(--hover-color) 0) 0 var(--_d, 0)/100% 200% no-repeat;

        div.languageSelector {
            position: absolute;
            top: -30px;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            @media all and (max-width:640px){
                width: 95%;
                margin: 0 auto;
            }
          
            img.language {
              max-width: 30px;
              max-height: 20px;
              margin-left: 10px;
              cursor: pointer;
              opacity: 0.6;
              transition: opacity 0.3s ease;
          
              &.active {
                opacity: 1;
                border: 1px solid #fff;
                border-radius: 3px;
              }
              &:hover{
                opacity: 1;
              }
            }
          }
          
        @media all and (max-width:768px){
            flex-direction: column;
        }

        .home-video-container{
            flex: 11;
            height: 100%
        }

        .home-schedule-card{
            margin: 10px;
             flex: 4;
             height: 100%;
             @media all and (max-width:768px){
                margin: 0px;
             }
             
        }
    }
}