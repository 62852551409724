@mixin buttonStyle{
    background-color: var(--secondary-bg-color);
    color: var(--white-color);
    border: none;
    outline: none;
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 6px;
    width: 90%;
    @media all and (max-width:640px){
        width: 68%;
    }
  }

  div.scheduleContainer{
    h1.heading{
        font-size: 22px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        margin-bottom: 30px;
        @media all and (max-width:640px){
            padding-left: 10px;
        }
    }
    div.daysContainer{
        div.day{
            text-align: center;
            button{
                @include buttonStyle;
                transition: 0.4s ease-in-out;
                &.active{
                    background-color: var(--primary-color);
                }
                @media all and (min-width:980px){

                    &:hover{
                        background-color: var(--hover-color);
                        
                    }
                    &.active{
                        &:hover{
                            background-color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
    div.earlierShows{
        text-align: center;
        margin-top: 30px;
        button{
            border: 1px solid var(--primary-color);
            background-color: transparent;
            color: var(--primary-color);
            padding: 6px 20px;
            font-size: 14px;
            border-radius: 6px;
            width: 15%;
            transition: 0.3s ease-in-out;
            @media all and (max-width:1200px){
                width: 20%;
            }
            @media all and (max-width:980px){
                width: 25%;
            }
            @media all and (max-width:769px){
                width: 30%;
            }
            @media all and (max-width:769px){
                width: 30%;
            }
            @media all and (max-width:640px){
                width: 35%;
            }
            @media all and (max-width:480px){
                width: 45%;
            }
            @media all and (max-width:360px){
                width: 50%;
            }
            svg{
                transition: 0.3s ease-in-out;
            }
            &:hover{
                border-color: var(--icons-color);
                color: var(--icons-color);
            }

            &:hover svg{
                fill: var(--icons-color);
            }
        }
    }
    
  }
  div.schedulebox{
    padding-bottom: 130px;
    div.gradientBg{
        bottom: -21px;
        height: 102px;
        button.showMore{
            margin-top: 126px;
        }
    }
  }

  div.paddingShowContainer{
    padding-bottom: 30px !important;
  }

  h1.marginScheduleContainer{
    margin-bottom: 0px !important;
    font-size: medium !important;
    font-weight: 700 !important;
  }