:root{
  --primary-color:#e72a31;
  --bg-color:#101010;
  --secondary-bg-color:#181818;
  --white-color:#f8f8f8;
  --icons-color:#ff0103;
  --hover-color:#1d2124;
  --text-color:#979797;
  --heading-color:#e5e5e5;
}
@import "./views/pages/LivePage/scss/Live";
@import "./Components/ScheduleDays/ScheduleDays";
@import "./Components/ScheduleCard/ScheduleCard";
// @import "./Components/PresenterCard/scss/PresenterCard.scss";
@import "./views/pages/AboutSection/scss/LeaderShip";
@import "./views/pages/Home/Home";
@import "./Components/CategoryTile/CategoryTile";
@import "./Components/ShowCard/ShowCard";
@import "./Components/MagazineCard/MagazineCard";
@import "./views/pages/AboutSection/scss/AboutRevelation";
@import "./Components//SubscribeBanner/SubscribeBanner";
@import "./Components/Products/Products";
@import "./views/pages/NewsSection/scss/LatestNews";
@import "./Components/NewsCard/NewsCard";
@import "./views/pages/OnDemand/OnDemand";
@import "./views/pages/PrayerSection/scss/prayer";
@import "./views/pages/Reminder&Fav/scss/ReminderAndFav";
@import "./views/pages/PresentersSection/scss/Presenters";
@import "./views/pages/PresentersSection/scss/PresentersInfo";
@import "./views/pages/DonationSection/scss/Donation";
@import "./views/pages/BuildingFund/BuildingFund";
@import "./views/pages/IsraelTour/IsraelTour";
@import "./views/pages/ArticleInfo/ArticleInfo";
@import "./views/pages/StatementOfFaith/StatementOfFaith";
@import "./views/pages/HealingScriptures/HealingScriptures";
@import "./views/pages/VideoPlayer/VideoPlayer";
@import "./views/pages/DonationSection/scss/DonateByDebit";
@import "./views/pages/ShowInformation/ShowInformation";
@import "./views/pages/Contact/Contact";
@import "./views/pages/SearchResult/SearchResult";
@import "./views/pages/AccountSetting/accountSettingStyles/accountSetting";
@import "./views/pages/ChangePassword/changepaswrdStyles/changepwd";
@import "./views/pages/Subscribe/subscribeStyles/subscribestyles";
@import "./views/pages/AllShows/AllShows";
@import "./views/pages/Register&Login/register&login";
@import "./views/pages/ForgetPassword/ForgotPassword";
@import "./views/pages/ForgetPassword/ResetPassword";
@import "./views/pages/DonationSection/GiftAidInfo/GiftAidInfo";
@import "./views/pages/PrivacyPolicy/PrivacyPolicy";
@import "./views/pages/PageNotFound/PageNotFound";
@import "./views/pages/AboutSection/scss/OurStudio";
@import "./views/pages/Devices/scss/Spotify";
@import "./views/pages/Devices/scss/Other";
@import "./views/pages/Devices/scss/Smart";
@import "./views/pages/Devices/scss/Roku";
@import "./views/pages/Devices/scss/Sattlite";
@import "./views/pages/PrayerSection/scss/prayerSuccess";
@import "./views/pages/Magazines/scss/magazinesList";
@import "./views/pages/SearchResult/SearchShowAll";
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1.nodatas{
  text-align: center;
  color: var(--primary-color);
  padding: 72px 0;
}
.uv-popover{
  z-index: 999999999 !important;
}
div.topSpace{
    padding-top: 130px;
    &.emptySpace{
      padding-top: 230px;
    }

}
div.bannerSection{
  padding-top: 118px;
  margin-bottom: 21px;
  text-align: center;
  &.topSpace{
    padding-top: 222px;
  }
  
  div.imageContainer{
      width: 53% !important;
      display: block !important;
      margin: 0 auto !important;
      cursor: pointer;
      img{
          width: 100%;
          display: block;
      }
      @media all and (max-width:640px){
          width: 91% !important;
          margin: 0 auto;
      }
  }
}
.slick-prev {
  left: -20px !important;
}
.swiper-button-prev{
  left: -5px !important;
}
@media all and (max-width:640px){
  .slick-prev {
    left: 19px !important;
  }
  .slick-next {
    right: 17px !important;
  }
}
.swiper{
  padding: 0 40px !important;

}
.slick-prev{
  transform: rotate(-180deg) !important;
  top: 22% !important;
  &::before{
    color: transparent !important;
    background-image: url(./images/svg/nav-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;


  }
}
.slick-next{
  top: 50% !important;
  
  &::before{
    color: transparent !important;
    background-image: url(./images/svg/nav-arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
}
.swiper-button-next {
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 38% !important;
  transition: 0.3s ease-in-out;

  &:hover{
    right: 2px !important;
  }
  @media all and (max-width:1600px){
    top: 46% !important;
  }
  @media all and (max-width:980px){
    top: 50% !important;
  }
  @media all and (max-width:768px){
    top: 46% !important;
  }
  @media all and (max-width:640px){
    top: 50% !important;

  }
  @media all and (max-width:480px){
    top: 45% !important;

  }
}
.inner.lds-dual-ring {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  margin: 0 auto;
}
.inner.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #e50914 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
div.loadingContainer{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
div.loadingOverlay{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.spinnerIcon{
  z-index: 99999;
  text-align: center;
  
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.swiper-button-prev{
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(-180deg);
  top: 38% !important;
  transition: 0.3s ease-in-out;

  &:hover{
    left: -9px !important;
  }
  @media all and (max-width:1600px){
    top: 46% !important;
  }
  @media all and (max-width:980px){
    top: 50% !important;
  }
  @media all and (max-width:768px){
    top: 46% !important;
  }
  @media all and (max-width:640px){
    top: 50% !important;

  }
  @media all and (max-width:480px){
    top: 45% !important;

  }
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev::after {
  display: none;
}
div.ondemand{
  @media all and (min-width:1200px){
    .swiper-slide{
        margin-right: 0 !important;
    }
}
}
div.tileContainer{

  .slick-track{
    padding: 100px 0 168px !important;
  }
  .swiper-wrapper{
    padding-top: 15px !important;
    position: relative;
    margin-bottom: 93px;
  }
}
span.whatsapp{
  color: #2a9b77 !important;
  font-size: 14px !important;
}
a{
  text-decoration: none;
  color: var(--text-color);
}
a:hover{
  color: var(--text-color);
}
span{
  color: var(--white-color);
  font-weight: 400;
}
ul{
  list-style: none;
}
h1,h2,h3,h4,h5,h6{
  color: var(--heading-color);
  margin: 0;
}
p{
  color: var(--text-color);
  font-weight: 600;
}
ul{
  margin: 0;
  padding: 0;
}
button{
  outline: none;
  border: none;
}

div.cardGradient{
  background: url(./images/gradient.svg) repeat-x center bottom;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 87px;
    transform: translateY(100px);
    transition: 0.4s ease-in-out;
    padding: 25px 0;
    @media all and (max-width:980px){
    }
    button{
      width: 30%;
      background-color: var(--primary-color);
      color: var(--white-color);
      padding: 8px 0;
      font-size: 14px;
      border-radius: 6px;
      @media all and (max-width:1200px){
        width: 45%;
      }
      @media all and (max-width:980px){
        font-size: 13px;
        padding: 6px 0;
      }
    }
}
button.shareButton{
                background-color: transparent;
                border: 1px solid var(--primary-color);
                padding: 3px 10px;
                width: 10%;
                border-radius: 6px;
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                transition: 0.4s ease-in-out;
                font-size: 13px;
                &.active{
                  svg.active{
                      transform: rotate(0deg);
                  }
              }
              @media all and (max-width:1400px){
                  width: 12%;
              }
              @media all and (max-width:1200px){
                  width: 14%;
                  svg.share{
                      margin-right: 6px;
                  }
              }
              @media all and (max-width:980px){
                  width: 20%;
                  font-size: 14px;
              }
              @media all and (max-width:640px){
                 width: 25%;
              }
              @media all and (max-width:480px){
                  width: 40%;
                  padding: 3px 15px;
               }
               @media all and (max-width:360px){
                  width: 45%;
                  padding: 3px 15px;
               }
              span{
                  color: var(--primary-color);
                  margin :0 10px;
                  transition: 0.4s ease-in-out;
              }
              svg.share{
                  margin-right: 8px;
                  @media all and (max-width:1200px){
                          margin-right: 6px;
                  }
              }
              svg{
                  transition: 0.4s ease-in-out;
              }
              svg.active{
                  transform: rotate(180deg);
              }
              &:hover{
                  border-color: var(--icons-color);
                  span{
                      color: var(--icons-color);
                  }
                  svg{
                     fill: var(--icons-color);
                  }
              }
}
div.gradientBg{
  background: url(./images/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 91px;
  width: 100%;
  z-index: 9;
  height: 101px;
  button.showMore{
      border: 1px solid var(--primary-color);
background-color: transparent;
color: var(--primary-color);
padding: 4px 10px 4px 15px;
font-size: 13px;
border-radius: 6px;
width: 10rem;
transition: 0.3s ease-in-out;

@media all and (max-width:1200px){
  font-size: 13px;
}

svg{
  transition: 0.3s ease-in-out;
  margin-left: 12px;
  width: 17px !important;
  height: 17px !important;
}
&:hover{
  border-color: var(--icons-color);
  color: var(--icons-color);
}

&:hover svg{
  fill: var(--icons-color);
}
  }
}
button.bg-button{
  background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    outline: none;
    font-size: 13px;
    padding: 6px 30px;
    border-radius: 6px;
    transition: 0.4s ease-in-out;
    border: 1px solid var(--primary-color);
    display: flex;
justify-content: center;
align-items: center;
    svg{
      margin-left: 6px;
      width: 25px !important;
      height: 25px !important;
      }
    &:hover{
      background-color:var(--white-color);
      color: var(--primary-color);
    }
    @media all and (max-width:1400px){
            font-size: 13px;
    }
    @media all and (max-width:1200px){
        padding: 5px 25px;
    }
    @media all and (max-width:640px){
      font-size: 12px;
   }
    @media all and (max-width:360px){
        padding: 5px 18px;
    }
}
button.transparent{
  border: 1px solid var(--primary-color);
background-color: transparent;
color: var(--primary-color);
padding: 6px 30px;
font-size: 13px;
border-radius: 6px;
width: 15%;
transition: 0.4s ease-in-out;
display: flex;
justify-content: center;
align-items: center;
@media all and (max-width:1200px){
width: 20%;
}
@media all and (max-width:980px){
width: 25%;
}
@media all and (max-width:769px){
width: 30%;
}
@media all and (max-width:769px){
width: 30%;
}
@media all and (max-width:640px){
width: 35%;
}
@media all and (max-width:480px){
width: 45%;
}
@media all and (max-width:360px){
width: 50%;
}
svg{
transition: 0.3s ease-in-out;
margin-left: 6px;
width: 25px !important;
height: 25px !important;
}
&:hover{
border-color: var(--icons-color);
color: var(--icons-color);
}

&:hover svg{
fill: var(--icons-color);
}
}
.swiper-pagination-bullet-active{
  background-color: var(--primary-color) !important;
}
.vjs_video_3_ima-ad-container{
  z-index: 1 !important;
}